import {CloseOutlined, SearchOutlined} from '@ant-design/icons'
import {useEffect, useState} from 'react'
import Images from '../../../assets/gen'
import {BaseText} from '../../../components'
import {BaseInput} from '../../../components/input/BaseInput'
interface IProps {
  value: {
    fullAddress: string
    lat: number
    lng: number
  }
  onChange: (value: {fullAddress: string; lat: number; lng: number}) => void
}

export const SelectAddress = (props: IProps) => {
  const {value, onChange} = props
  const [openListSelectAddress, setOpenListSelectAddress] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const [listAddress, setListAddress] = useState([])

  const handleSelectedUser = (address: any) => {
    if (address) {
      const dataConvert = {
        fullAddress:
          address?.plus_code?.compound_code || address?.formatted_address,
        lat: address?.geometry?.location?.lat,
        lng: address?.geometry?.location?.lng,
      }
      onChange(dataConvert)
      setOpenListSelectAddress(false)
    }
  }

  const fetchGeocodeData = async (search: string) => {
    const apiKey = 'AIzaSyCCM4xlW9R30ankpI8RkN0x32RYPhnMf84'
    const encodedSearch = encodeURIComponent(search)

    try {
      const res = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedSearch}&key=${apiKey}`,
        {
          method: 'GET',
        }
      )

      if (!res.ok) {
        throw new Error(
          `Google Maps API error: ${res.status} ${res.statusText}`
        )
      }

      const data = await res.json()

      if (data.status === 'OK') {
        console.log('Geocode results:', data.results)

        return data.results
      } else {
        console.log('No results found for the address.')
      }
    } catch (error) {
      console.error('Error fetching geocode data:', error)
    }
  }

  const getListAddress = async (address: string) => {
    const fullAddress = address
    try {
      const response: any[] = await fetchGeocodeData(fullAddress)

      if (Array.isArray(response)) {
        setListAddress(response as any)
      }
    } catch (error) {
      console.error('Error occurred while fetching geocode:', error)
    }
  }

  useEffect(() => {
    if (valueSearch) {
      getListAddress(valueSearch)
    }
  }, [valueSearch])

  return (
    <>
      <div
        onClick={() => {
          setOpenListSelectAddress(true)
        }}
      >
        <BaseInput
          // title="매장 주소(위치기반 적용)"
          placeholder='주소입력'
          value={value?.fullAddress}
        />
      </div>
      {openListSelectAddress && (
        <div className='flex flex-col gap-2'>
          <div className='flex justify-end'>
            <CloseOutlined
              className='text-2xl text-gray-500 cursor-pointer'
              onClick={() => setOpenListSelectAddress(false)}
            />
          </div>
          <BaseInput
            placeholder='Search user with ID or Nickname'
            className='flex'
            value={valueSearch}
            onChange={(value) => {
              setValueSearch(value)
            }}
            iconLeft={
              <SearchOutlined className='mr-3 text-2xl text-darkNight500' />
            }
          />
          {listAddress.length > 0 ? (
            <div className='flex flex-col gap-2 h-[200px] overflow-auto'>
              {listAddress.map((address: any) => {
                return (
                  <div
                    key={address.id}
                    className='flex items-center gap-3 cursor-pointer'
                    onClick={() => handleSelectedUser(address)}
                  >
                    <div className='flex flex-wrap '>
                      {address?.plus_code?.compound_code ||
                        address?.formatted_address}
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <div className='flex flex-col gap-2 h-[200px] justify-center items-center'>
              <img
                src={Images.dataEmpty}
                alt='avatar'
                className='w-10 h-10 rounded-full'
              />
              <BaseText locale>No data address</BaseText>
            </div>
          )}
        </div>
      )}
    </>
  )
}
