import { Spin } from 'antd'
import i18next from 'i18next'
import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import { HeadManager } from './components/HeadManager'
import { useSocketContext } from './contexts/SocketContext'
import Router from './routers'
import { useCommonState } from './stores/commonStorage'
import { useLocalStorage } from './stores/localStorage'
import { SOCKET_EVENT } from './utils/constants'

const AppMain: React.FC = () => {
  const {locale, setRefreshToken, setAccessToken, setExpiresIn} =
    useLocalStorage((state) => state)
  const {loading} = useCommonState((state) => state)
  const socket = useSocketContext()
  useEffect(() => {
    i18next.changeLanguage(locale)
  }, [locale])

  useEffect(() => {
    function onConnect() {
      console.log('connected')
    }

    function onDisconnect() {
      console.log('disconnected')
    }

    socket?.on('connect', onConnect)
    socket?.on('disconnect', onDisconnect)
    socket?.on(SOCKET_EVENT.LOG_OUT, () => {
      setAccessToken('')
      setRefreshToken('')
      setExpiresIn(0)
      window.location.reload()
    })
    socket?.on('connect_error', (error: any) => {
      console.error('Connect error:', error)
    })

    return () => {
      socket?.off('connect', onConnect)
      socket?.off('disconnect', onDisconnect)
      socket?.off(SOCKET_EVENT.LOG_OUT)
      socket?.off('connect_error')
    }
  }, [socket])

  return (
    <div className='relative'>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      {loading && (
        <div
          style={{zIndex: 100000000000}}
          className='absolute flex justify-center items-center bg-opacity-40 top-0 left-0 w-full h-full  bg-darkNight400'
        >
          <Spin size='large' />
        </div>
      )}
      <HeadManager />
    </div>
  )
}

export default AppMain
