import React, {useEffect, useMemo, useState} from 'react'
import {BaseTable, BaseText, CustomButton} from '../../../components'
import {useBlogState} from '../store'
import {BlogApi} from '../../../apis/blogApi'
import {BlogInterface} from '../../../entities/blog.entity'
import {
  Button,
  InputNumber,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Select,
  Space,
  TableColumnsType,
} from 'antd'
import {useTranslation} from 'react-i18next'
import Images from '../../../assets/gen'
import {formatTime} from '../../../utils/common'
import {Preview} from './Preview'
import {showError, showSuccess} from '../../../utils/showToast'
import {
  CaretDownOutlined,
  CloseOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {ThemaInterface} from '../../../entities'
import {ThemaApi} from '../../../apis/themaApi'
import {CategoryApi} from '../../../apis/categoryApi'
import {BaseInput} from '../../../components/input/BaseInput'
import {settingApi} from '../../../apis/settingApi'
import {toNamespacedPath} from 'path/posix'
import {use} from 'i18next'

export default function BlogList() {
  return (
    <div className='w-full h-full flex flex-row'>
      <BuildBlogList />
      <Preview />
    </div>
  )
}

const BuildBlogList = () => {
  const {t} = useTranslation()
  const [count, setCount] = useState(0)
  const [blogs, setBlogs] = useState<BlogInterface[]>([])
  const pageBlog = sessionStorage.getItem('pageBlog')
  const [page, setPage] = useState(pageBlog ? Number(pageBlog) : 1)
  const limit = 50
  const {setBlog, tab, setTab} = useBlogState((state) => state)
  const [listRowSelected, setListRowSelected] = useState<string[]>([])
  const [themas, setThemas] = useState<any>([])
  const [categories, setCategories] = useState<any>([])
  const themaBlog = sessionStorage.getItem('themaBlog')
  const categoryBlog = sessionStorage.getItem('categoryBlog')
  const [selectedThema, setSelectedThema] = useState(themaBlog || t('All'))
  const [selectedCategory, setSelectedCategory] = useState(
    categoryBlog || t('All')
  )
  const [perPageMobile, setPerPageMobile] = useState<number>(0)
  const [perPageDesktop, setPerPageDesktop] = useState<number>(0)
  const [openFilter, setOpenFilter] = useState(false)
  const [valueKeywordFilter, setValueKeywordFilter] = useState('')
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setListRowSelected(newSelectedRowKeys as string[])
  }

  const deleteBlog = async (id: string) => {
    try {
      await BlogApi.delete(id)
      _getList()
      showSuccess('Delete successfully')
    } catch (error) {
      showError(error)
    }
  }
  const deleteBlogMultiple = async () => {
    try {
      await BlogApi.deleteMultiple(listRowSelected)
      _getList()
      setListRowSelected([])
      showSuccess('Delete successfully')
    } catch (error) {
      showError(error)
    }
  }
  const columns: TableColumnsType = [
    {
      title: t('No'),
      width: 60,
      render: (value, record, index) => (
        <BaseText>{index + 1 + (page - 1) * limit}</BaseText>
      ),
    },
    {
      title: `${t('Post title')}(${count})`,
      render: ({title}, record, index) => (
        <BaseText medium className='line-clamp-2'>
          {title}
        </BaseText>
      ),
    },
    {
      title: t('Views'),
      width: 90,
      render: ({view}, record, index) => (
        <BaseText medium className='text-primary'>
          {view}
        </BaseText>
      ),
    },
    {
      title: t('Date Created'),
      render: ({created_at}, record, index) => (
        <BaseText medium>{formatTime(created_at)}</BaseText>
      ),
    },
    {
      title: t('Actions'),
      width: 100,
      render: ({id}, record: any) => (
        <div className='flex flex-row items-center'>
          <img
            onClick={() => {
              setTab(1)
              setBlog(record)
              sessionStorage.setItem('pageBlog', page.toString())
              sessionStorage.setItem('themaBlog', selectedThema)
              sessionStorage.setItem('categoryBlog', selectedCategory)
            }}
            src={Images.edit}
            className='w-6 h-6 ml-3 cursor-pointer'
          />
          <Popconfirm
            onConfirm={() => deleteBlog(id)}
            title={t('Delete')}
            description={t('Are you sure to delete')}
          >
            <img src={Images.trash} className='w-5 h-5 ml-3 cursor-pointer' />
          </Popconfirm>
        </div>
      ),
    },
  ]

  const _getList = async () => {
    try {
      const {rows, count} = await BlogApi.getList({
        limit: limit,
        page,
        fields:
          (selectedThema !== '' && selectedThema !== t('All')) ||
          (selectedCategory !== '' && selectedCategory !== t('All'))
            ? generateFields()
            : '["$all",{"category":["$all"]}]',
        filter: JSON.stringify({title: {$ilike: `%${valueKeywordFilter}%`}}),
      })
      setBlogs(rows)
      setCount(count)
    } catch (error) {}
  }

  const getListThema = async () => {
    try {
      const data: Array<ThemaInterface> = await ThemaApi.getList()
      const transformedData = data.map((item) => ({
        value: item.id,
        label: item.name,
      }))
      // // add item to first array
      transformedData.unshift({value: t('All'), label: t('All')})

      setThemas(transformedData)
    } catch (error) {
      return []
    }
  }

  const getListCategory = async () => {
    try {
      if (selectedThema && selectedThema !== '') {
        const data = await CategoryApi.getList({
          filter:
            selectedThema !== t('All')
              ? `{"thema_id":"${selectedThema}"}`
              : undefined,
        })
        const transformedData = data.map((item: any) => ({
          value: item.id,
          label: item.name,
        }))
        transformedData.unshift({value: t('All'), label: t('All')})
        setCategories(transformedData)
      } else {
        setCategories([])
      }
    } catch (e) {
      return []
    }
  }
  const getSettingPerPageBlog = async () => {
    try {
      const params = {
        fields: '["$all"]',
        filter: JSON.stringify({field: 'BLOG_LIMIT_PER_PAGE'}),
      }
      let result: any = await settingApi.getList(params)
      if (result.code === 200) {
        console.log(11, result)
        setPerPageMobile(
          result?.results?.objects?.rows[0].value_obj?.mobile || 0
        )
        setPerPageDesktop(
          result?.results?.objects?.rows[0].value_obj?.desktop || 0
        )
      }
    } catch (error) {}
  }
  useEffect(() => {
    getSettingPerPageBlog()
  }, [])
  const setPerPageBlog = async (data: {mobile?: number; desktop?: number}) => {
    await settingApi.setBlogPerPage(data)
    notification.success({message: '설정되었습니다'})
  }

  const generateFields = () => {
    let filterThema = ''
    if (
      selectedCategory &&
      selectedCategory !== '' &&
      selectedCategory !== t('All')
    ) {
      filterThema += `,{"$filter":{"id":"${selectedCategory}"}}`
    } else if (
      selectedThema &&
      selectedThema !== '' &&
      selectedThema !== t('All')
    ) {
      filterThema += `,{"$filter":{"thema_id":"${selectedThema}"}}`
    }
    return `["$all",{"category":["$all",{"thema":["$all"]}${filterThema}]}]`
  }

  const getThemaName = useMemo(() => {
    const thema = themas.find((item: any) => item.value === selectedThema)
    if (!thema || thema.label === t('All')) {
      if (!selectedCategory || selectedCategory === t('All')) {
        return 'Filter'
      } else {
        return ''
      }
    } else {
      if (!selectedCategory || selectedCategory === t('All')) {
        return thema.label
      } else {
        return `${thema.label} > `
      }
    }
  }, [selectedThema, selectedCategory])

  const getCategoryName = useMemo(() => {
    const category = categories.find(
      (item: any) => item.value === selectedCategory
    )
    return category && category.label !== t('All') ? category.label : ''
  }, [selectedCategory])

  useEffect(() => {
    _getList()
  }, [page, selectedThema, selectedCategory])
  useEffect(() => {
    getListThema()
    sessionStorage.removeItem('pageBlog')
    sessionStorage.removeItem('themaBlog')
    sessionStorage.removeItem('categoryBlog')
  }, [])

  useEffect(() => {
    getListCategory()
  }, [selectedThema])

  const handleChangeTextKeyword = (value: string) => {
    setValueKeywordFilter(value)
  }

  return (
    <div className='flex-1 border-r p-6 overflow-auto'>
      <div className='flex justify-between items-center'>
        <BaseText bold size={24} locale>
          Article list
        </BaseText>

        {/*<Select*/}
        {/*    suffixIcon={<CaretDownOutlined />}*/}
        {/*    placeholder={t("Thema")}*/}
        {/*    style={{ width: 110 }}*/}
        {/*    onChange={(v)=>setSelectedThema(v)}*/}
        {/*    options={themas}*/}
        {/*    value={selectedThema !== "" && selectedThema !== t("All") ? selectedThema : undefined}*/}
        {/*/>*/}
        <div className='flex items-center gap-4'>
          <div className='flex items-center gap-2'>
            <div className='justify-start text-black text-base font-normal'>
              {t('Set exposure count')}
            </div>
            <Space.Compact style={{width: '168px'}}>
              <InputNumber
                value={perPageDesktop}
                onChange={(e) => setPerPageDesktop(e || 0)}
                className='!w-[106px]'
                prefix={'PC :'}
              />
              <Button
                className='bg-black text-white !max-w-[62px]'
                onClick={() => setPerPageBlog({desktop: perPageDesktop})}
              >
                {t('Apply blog')}
              </Button>
            </Space.Compact>
            <Space.Compact style={{width: '168px'}}>
              <InputNumber
                className='!w-[106px]'
                prefix={'모바일 :'}
                value={perPageMobile}
                onChange={(e) => setPerPageMobile(e || 0)}
              />
              <Button
                className='bg-black text-white !max-w-[62px]'
                onClick={() => setPerPageBlog({mobile: perPageMobile})}
              >
                {t('Apply blog')}
              </Button>
            </Space.Compact>
          </div>
          <Popover
            placement='bottomRight'
            open={openFilter}
            onOpenChange={(e) => setOpenFilter(e)}
            trigger='click'
            content={
              <div className='flex w-[536px] flex-col px-7 pt-3 pb-4'>
                <div className='py-4 flex flex-col items-start border-b border-[#E8E8E8]'>
                  <span className='text-darkNight900 font-bold text-xl'>
                    테마
                  </span>
                  <Radio.Group
                    value={selectedThema}
                    name='radioGroupThema'
                    className='py-4 flex flex-col justify-between max-h-[300px] overflow-auto w-full'
                    onChange={(e) => {
                      setSelectedThema(e.target.value)
                      setSelectedCategory(t('All'))
                    }}
                  >
                    {themas.map((thema: any) => (
                      <Radio
                        name={thema.label}
                        value={thema.value}
                        key={thema.value}
                        className={'flex-1 text-[16px] font-medium'}
                      >
                        {' '}
                        {thema.label}{' '}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
                <div className='pt-4'>
                  <span className='text-darkNight900 font-bold text-xl'>
                    카테고리
                  </span>
                  <Radio.Group
                    value={selectedCategory}
                    name='radioGroupCategory'
                    className='py-4 flex flex-col justify-between max-h-[300px] overflow-auto'
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    {categories.map((category: any) => (
                      <Radio
                        name={category.label}
                        value={category.value}
                        key={category.value}
                        className={'flex-1 text-[16px] font-medium'}
                      >
                        {' '}
                        {category.label}{' '}
                      </Radio>
                    ))}
                  </Radio.Group>
                </div>
              </div>
            }
          >
            <div className='h-12 px-4 py-2.5 rounded-[10px] border border-[#d0d0d0] justify-center items-center flex cursor-pointer'>
              <div className='justify-start items-center gap-3 flex'>
                <div className="w-[100px] text-black text-sm font-bold font-['Noto Sans KR'] leading-tight">
                  {`${getThemaName}${getCategoryName}`}
                </div>
              </div>
              <div className='w-6 h-6 relative'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                  fill='none'
                  className={openFilter ? 'rotate-180' : undefined}
                >
                  <path
                    d='M18 10L12.7071 15.2929C12.3166 15.6834 11.6834 15.6834 11.2929 15.2929L6 10'
                    stroke='black'
                    stroke-width='1.5'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                  />
                </svg>
              </div>
            </div>
          </Popover>
        </div>
      </div>
      <div className='flex gap-4 text-base font-medium leading-6 whitespace-nowrap max-w-[651px] max-md:flex-wrap w-full my-4'>
        <BaseInput
          placeholder='Keyword'
          className='w-full'
          value={valueKeywordFilter}
          onChange={(value) => {
            handleChangeTextKeyword(value)
          }}
          iconLeft={
            <SearchOutlined className='mr-3 text-2xl text-darkNight500' />
          }
        />
        <CustomButton
          primary
          bold
          locale
          className='flex items-center justify-center p-3 text-base text-white bg-blue-600 rounded-xl h-12'
          onClick={() => {
            // getListSite(selectedButton)
            _getList()
          }}
        >
          Search
        </CustomButton>
      </div>
      <BaseTable
        sticky={{offsetHeader: -25}}
        onRowClick={(record, index) => {
          setBlog(record)
        }}
        pagination={{
          current: page,
          pageSize: limit,
          total: count,
          onChange: (page: number, pageSize: number) => {
            setPage(page)
          },
        }}
        columns={columns}
        data={blogs.map((blog) => ({...blog, key: blog.id}))}
        onSelectChange={onSelectChange}
        selectedKeys={listRowSelected}
      />
      {listRowSelected.length > 0 && (
        <div className='fixed bottom-6 right-1/4 left-1/4'>
          <div className='flex bg-white gap-6 px-6 py-4  rounded-lg shadow-xl'>
            <div className='flex justify-center gap-2 px-3 py-3 rounded-full bg-darkNight50'>
              <CloseOutlined className='text-xl text-black cursor-pointer' />
              <BaseText bold size={16}>
                {t('선택됨')}{' '}
                <span className='text-primary'>{listRowSelected.length}</span>
              </BaseText>
            </div>
            <div className='flex flex-1 items-center justify-end gap-2'>
              <Popconfirm
                onConfirm={() => deleteBlogMultiple()}
                title={t('Delete')}
                description={t('Are you sure to delete')}
              >
                <img src={Images.trash} className='w-6 h-6 cursor-pointer' />
              </Popconfirm>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
