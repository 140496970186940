import {Helmet} from 'react-helmet'
import {useLocalStorage} from '../../stores/localStorage'

export const HeadManager = () => {
  const {appName, icon} = useLocalStorage((state) => state)

  return (
    <Helmet>
      <title>{appName}</title>
      <link rel='icon' type='image/x-icon' href={icon} />
    </Helmet>
  )
}
